* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @media (min-width: 601px) {
  .MuiToolbar-regular-50 {
      min-height: 64px;
      background: linear-gradient(to bottom, rgb(128, 0, 128) 20%, #2a2a2a 80%);
      
    }
  }
  
@media (max-width: 600px) {
  .MuiToolbar-regular-50{
      background: linear-gradient(to bottom, rgb(128, 0, 128) 20%, #2a2a2a 80%);
    }
} */
