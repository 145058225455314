@import url(https://fonts.googleapis.com/css?family=Cantarell&display=swap);
* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* @media (min-width: 601px) {
  .MuiToolbar-regular-50 {
      min-height: 64px;
      background: linear-gradient(to bottom, rgb(128, 0, 128) 20%, #2a2a2a 80%);
      
    }
  }
  
@media (max-width: 600px) {
  .MuiToolbar-regular-50{
      background: linear-gradient(to bottom, rgb(128, 0, 128) 20%, #2a2a2a 80%);
    }
} */

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}










.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
@media only screen and (min-width: 600px) {
  .navButtons {
    position: absolute;
    right: 0
  }
  .Eventlist {
    color: magenta;
    font-size: 36px !important;
    padding-bottom: 10;
  }
  .events{
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
}
@media only screen and (max-width: 599px) {
  .Eventlist {
    color: magenta;
    font-size: 12px !important;
    padding-bottom: 10;
  }

  .viewButtons {
    display:none;
  }

  .events{
    margin: 0;
    padding: 0;
    font-size: 10px;
  }
}

.rbc-event-label {
  display: none;
}

.modal {
  font-size: 12px;
  background-image: -webkit-gradient(linear, left top, right bottom, from(black), to(purple));
  background-image: -webkit-linear-gradient(top left, black, purple);
  background-image: linear-gradient(to bottom right, black, purple);
  color: #FFFFFF;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid black;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: purple;
  border-radius: 18px;
  border: 1px solid purple;
}
.lecture {
    font-family: 'Cantarell', sans-serif;
}
.table {
    margin:0 auto
}
