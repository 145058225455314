@media only screen and (min-width: 600px) {
  .navButtons {
    position: absolute;
    right: 0
  }
  .Eventlist {
    color: magenta;
    font-size: 36px !important;
    padding-bottom: 10;
  }
  .events{
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
}
@media only screen and (max-width: 599px) {
  .Eventlist {
    color: magenta;
    font-size: 12px !important;
    padding-bottom: 10;
  }

  .viewButtons {
    display:none;
  }

  .events{
    margin: 0;
    padding: 0;
    font-size: 10px;
  }
}

.rbc-event-label {
  display: none;
}

.modal {
  font-size: 12px;
  background-image: linear-gradient(to bottom right, black, purple);
  color: #FFFFFF;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid black;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: purple;
  border-radius: 18px;
  border: 1px solid purple;
}